import { DataTableHeader } from "@/mixins/DataTableMixin";
import { get, map } from "lodash";
import { CallbackWithResponse } from "@/types/utils";

export interface PaymentMethodPivotData {
  id: number;
  company_id: number;
  payment_method_id: number;
  config: Record<InvoicePaymentMethodItemKey, any>;
}

export type InvoicePaymentMethodCode =
  | "EFECTIVO"
  | "CHEQUE"
  | "CHEQUE_RECIBIDO"
  | "CHEQUE_EMITIDO"
  | "TARJETA"
  | "CUENTA_BANCARIA"
  | "CERTIFICADO"
  | "RESGUARDO"
  | "CONFORME";

export type InvoicePaymentMethodItemKey =
  | "bank_name"
  | "account_serial"
  | "account_number"
  | "account_type"
  | "issue_at"
  | "due_at"
  | "holder"
  | "number"
  | "type";

export const InvoicePaymentMethodItemsConfig: Record<string, DataTableHeader> =
  {
    bank_name: { value: "bank_name", text: "bank", class: "w-min-100" },
    account_serial: {
      value: "account_serial",
      text: "account.serial",
      class: "w-min-100",
    },
    account_number: {
      value: "account_number",
      text: "account.number",
      class: "w-min-150",
    },
    account_type: {
      value: "account_type",
      text: "account.type",
      class: "w-min-150",
    },
    account_code: {
      value: "account_code",
      text: "code.account",
      class: "w-min-100",
    },
    issue_at: { value: "issue_at", text: "issue.date", class: "w-min-100" },
    due_at: { value: "due_at", text: "due.date", class: "w-min-100" },
    holder: { value: "holder", text: "holder", class: "w-min-100" },
    number: { value: "number", text: "card.number", class: "w-min-150" },
    type: { value: "type", text: "type", class: "w-min-100" },
  };

export const InvoicePaymentMethodConfigItems = {
  CHEQUE: [
    "bank_name",
    "account_serial",
    "account_number",
    "issue_at",
    "due_at",
    "holder",
  ],
  TARJETA: [], // ["number", "holder"],
  CUENTA_BANCARIA: ["bank_name", "account_number", "account_type"],
  CERTIFICADO: [], // ["account_serial", "account_number", "issue_at"],
  RESGUARDO: [], // ["account_serial", "account_number", "type"],
  CONFORME: [], // ["account_number", "issue_at", "due_at"],
};

export const getTableHeaders = (
  sType: InvoicePaymentMethodCode,
  cb?: CallbackWithResponse
): DataTableHeader[] => {
  let arKeys: string[] = get(InvoicePaymentMethodConfigItems, sType, []);
  if (cb) arKeys = cb(arKeys);

  return map(arKeys, (sKey) => {
    return get(InvoicePaymentMethodItemsConfig, sKey);
  });
};
